<template>
  <div>
    <file-pond
      :ref="refName"
      :name="name"
      :label-idle="labelIdle"
      :accepted-file-types="acceptedFileTypes"
      :server="server"
      :files="files"
      :credits="credits"
      :style="buttonStyle"
      @init="pondInit"
    />
  </div>
</template>

<script>
import vueFilePond from 'vue-filepond'
// Import FilePond styles
import 'filepond/dist/filepond.min.css'

// Import FilePond plugins
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'

// Create component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
)

export default {
  components: {
    FilePond,
  },
  props: {
    uploadToId: {
      type: String,
      default: null,
    },
    refName: {
      type: String,
      default: 'pond-file',
    },
    name: {
      type: String,
      default: 'file-uploa',
    },
    folderPath: {
      type: String,
      default: 'others',
    },
    labelIdle: {
      type: String,
      default: 'Upload File',
    },
    acceptedFileTypes: {
      type: String,
      default: 'application/pdf, .docx, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    },
    credits: {
      type: String,
      default: 'false',
    },
    buttonStyle: {
      type: String,
      default: 'height: 60px!important;width: 250px;',
    },
  },
  data() {
    return {
      server: {},
      files: null,
    }
  },
  methods: {
    pondInit() {
      // ! this.$handleFilePondInit is working fine but it does not work if its in child component becaus the this. is different, havent found a way to change the value in parent
      this.$handleFilePondInit(this, this.uploadToId, this.refName, this.folderPath, true)
    },
  },
}
</script>

<style lang="scss" scoped>

</style>