<template>
  <div id="education">
    <div class="d-flex mb-1">
      <b-button
        id="add-education"
        variant="warning"
        class="mx-2"
        @click="createNewEducation"
      >Add new education history</b-button>
    </div>

    <div class="educations-container mb-1 mx-2">
      <b-row
        v-for="(item, index) in educationInfo"
        :key="index"
        class="vx-row mx-2"
      >
        <b-card
          border-variant="dark"
          bg-variant="transparent"
          class="shadow-none mx-auto mb-1"
        >
          <b-row class="vx-row w-full">
            <b-col
              cols="12"
              md="6"
              class="mt-1"
            >
              <label class="b-form-input--label">School</label>
              <b-form-input
                v-model="item.school_name"
                class="w-full"
                placeholder="School"
              />
            </b-col>
            <b-col
              cols="12"
              md="6"
              class="mt-1"
            >
              <label class="b-form-input--label">Field of Study</label>
              <b-form-input
                v-model="item.field_of_study"
                class="w-full"
                placeholder="Field of study"
              />
            </b-col>
            <b-col
              cols="12"
              md="6"
              class="mt-1"
            >
              <label class="b-form-input--label">Start Date</label>
              <div>
                <flat-pickr
                  v-model="item.start_date"
                  class="form-control"
                  :config="configdateTimePicker"
                  placeholder="Start Date"
                />
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
              class="mt-1"
            >
              <label class="b-form-input--label">End Date</label>
              <div>
                <flat-pickr
                  v-model="item.end_date"
                  class="form-control"
                  :config="configdateTimePicker"
                  placeholder="End Date"
                />
              </div>
            </b-col>

            <!-- <b-col
              cols="12"
              class="w-full mt-1"
            >
              <label class="b-form-input--label">Description</label>
              <b-form-textarea
                v-model="item.description"
                placeholder=""
                rows="2"
              />
            </b-col> -->
          </b-row>

          <div class="d-flex flex-row-reverse my-2">
            <b-button
              variant="danger"
              class=""
              @click="deleteEducation(item.id, index)"
            >Delete</b-button>
          </div>
        </b-card>
      </b-row>
    </div>

    <div class="d-flex flex-row-reverse">

      <!-- <b-button
        v-if="$route.name === 'listings-page' && educationInfo.length > 0"
        variant="warning"
        class="mx-2 click-save"
        @click="saveEducations"
      >Save Education(s)</b-button> -->
      <!-- <b-button
        v-if="$route.name === 'listings-page' && educationInfo.length > 0"
        variant="primary"
        class="mx-2 click-reset"
        @click="getEducations"
      >Reset</b-button> -->
    </div>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

import {
  cloneDeep, debounce, difference, orderBy,
} from 'lodash'

export default {
  components: {
    flatPickr,
  },
  data() {
    return {
      educationInfo: [],

      configdateTimePicker: {
        inline: false,
      },

      first_run: true,

      latestUpdates: {
        employment: false,
        education: false,
        account: false,
        poc: false,
      },
    }
  },
  computed: {
    computedEducation() {
      return cloneDeep(this.educationInfo)
    },
  },
  watch: {
    computedEducation: {
      // the callback will be called immediately after the start of the observation
      immediate: true,
      deep: true,
      handler(val, oldVal) {
        if (oldVal) {
          const outer_this = this
          if (!this.first_run && !this.latestUpdates.education) {
            if (val && val.length === 1) {
              this.latestUpdates.education = true
              debounce(params => {
                outer_this.updateEducation('update', val[0].id, 0)
              }, 2000)()
            } else if (val && val.length > 1) {
              const diff = val.map((item, index) => {
                if (oldVal) {
                  const old_values = Object.values(oldVal[index])
                  const new_values = Object.values(item)
                  const output = difference(old_values, new_values)

                  if (output.length > 0) {
                    return index
                  }
                }
              })

              for (const idx of diff) {
                if (idx) {
                  this.latestUpdates.education = true
                  debounce(() => {
                    outer_this.updateEducation('update', val[idx].id, idx)
                  }, 2000)()
                }
              }
            }
          } else {
            this.first_run = false
          }
        }
      },
    },
  },
  created() {
    this.getEducation()
  },
  methods: {
    getEducation() {
      this.$http
        .get('/api/education-data')
        .then(response => {
          this.educationInfo = response.data.message

          this.isLoading = false
          // this.$forceUpdate();
        })
        .catch(error => {})
    },

    createNewEducation() {
      const educationTemplate = {
        school_name: null,
        field_of_study: null,
        start_date: null,
        end_date: null,
        id: null,
      }
      if (this.educationInfo) {
        this.educationInfo.push(educationTemplate)
      } else {
        this.educationInfo = [educationTemplate]
      }
    },

    updateEducation(type, _id, index) {
      this.isLoading = true
      let proceed = true
      const specific_item = this.educationInfo[index]

      if (specific_item) {
        specific_item.type = type
        specific_item.data_id = _id

        // validation
        if (this.$isEmpty(specific_item.school_name)
          || this.$isEmpty(specific_item.field_of_study)
          || this.$isEmpty(specific_item.start_date)
          || this.$isEmpty(specific_item.end_date)
        ) {
          proceed = false
        }
        if (type === 'delete') {
          proceed = true
        }

        if (proceed) {
          this.$http
            .put('/api/education-data', specific_item)
            .then(response => {
              if (type === 'insert' || type === 'update') {
                if (Object.keys(response.data.output).length > 0) {
                  if (response.data.output.id) {
                    this.educationInfo[index].id = response.data.output.id
                  }
                }
                this.$toast(
                  {
                    component: this.$toastContent,
                    props: {
                      title: 'Education Data Update',
                      icon: 'CheckCircleIcon',
                      text: response.data.message,
                      variant: 'success',
                    },
                  },
                )
              } else {
                this.$toast(
                  {
                    component: this.$toastContent,
                    props: {
                      title: 'Education Data Deletion',
                      icon: 'AlertCircleIcon',
                      text: 'Data deleted successfully!',
                      variant: 'danger',
                    },
                  },
                )
                this.educationInfo.splice(index, 1)
              }
              this.isLoading = true

              this.$emit('educations-updated', true)
            })
            .catch(error => {})
        }

        // ! VERIFY: please verify if this is okay to move out from this.$http().then(), originally was below this.isLoading = true
        this.latestUpdates.education = false
      }
    },

    saveEducations() {
      this.educationInfo.forEach(education => {
        const data = education
        data.type = 'update'
        data.data_id = education.id
        this.$http
          .put('/api/education-data', data)
          .then(response => {
            if (type == 'insert' || type == 'update') {
              if (Object.keys(response.data.output).length > 0) {
                if (response.data.output.id) {
                  this.educationInfo[index].id = response.data.output.id
                }
              }
              this.$toast(
                {
                  component: this.$toastContent,
                  props: {
                    title: 'Education Data Update',
                    icon: 'AlertCircleIcon',
                    text: response.data.message,
                    variant: 'success',
                  },
                },
              )
            } else {
              this.$toast(
                {
                  component: this.$toastContent,
                  props: {
                    title: 'Education Data Deletion',
                    icon: 'AlertCircleIcon',
                    text: 'Data deleted successfully!',
                    variant: 'danger',
                  },
                },
              )
              this.educationInfo.splice(index, 1)
            }
            this.isLoading = true
            this.latestUpdates.education = false
          })
          .catch(error => {})
      })

      this.$emit('educations-updated', true)
    },

    deleteEducation(item_id, item_index) {
      if (item_id === 0) {
        this.educationInfo.splice(item_index, 1)
      } else {
        this.updateEducation('delete', item_id, item_index)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.educations-container {
  max-height: 45vh;
  overflow-y: scroll;
  overflow-x: none;
}
</style>
