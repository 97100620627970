// vuex/modules/app-ecommerce.js

export default {
  namespaced: true,
  state: {
    cartItemsCount: 0,
    cartProducts: [], // array to store cart products
  },

  getters: {
    getCartItemsCount: (state) => state.cartItemsCount,
    getCartProducts: (state) => state.cartProducts,
  },

  mutations: {
    UPDATE_CART_ITEMS_COUNT(state, count) {
      state.cartItemsCount = count;
    },
    SET_CART_PRODUCTS(state, products) {
      state.cartProducts = products;
    },
  },

  actions: {
    fetchCartProducts({ commit }) {
      // Example API call to fetch cart products
      // return axios.get('/api/cart/products')
      //   .then(response => {
      //     commit('SET_CART_PRODUCTS', response.data.products);
      //   });
    },
    removeProductFromCart({ commit, state }, { productId }) {
      // Example API call to remove product from cart
      // return axios.delete(`/api/cart/products/${productId}`)
      //   .then(() => {
      //     const updatedProducts = state.cartProducts.filter(product => product.id !== productId);
      //     commit('SET_CART_PRODUCTS', updatedProducts);
      //     commit('UPDATE_CART_ITEMS_COUNT', updatedProducts.length);
      //   });
    },
  },
};
