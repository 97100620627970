export const AccountTypes = {
  EMPLOYER: 'employer',
  AGENCY: 'agency',
  EDUCATION_INSTITUTION: 'education_institution',
  APPLICANT: 'applicant',
  TEMPORARY: 'temporary',
  SYSTEM: 'system',
}

export const UserGroups = {
  SUPERUSER: 'superuser',
  ADMIN: 'admin',
}
