<template>
  <div>
    <b-button
      v-if="file && !$isEmpty(file)"
      id="#button-with-loading"
      class="mr-2 w-full shadow-lg"
      variant="primary"
      style="height: 65px;width: 250px;"
      @click="viewDocument()"
    >{{ title }}</b-button>

    <b-modal
      v-model="view_document_popup"
      centered
      hide-footer
      size="lg"
      :title="title"
      @close="viewDocumentClosed"
    >
      <b-overlay
        variant="white"
        :show="isLoading"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
      >
        <div
          v-if="document_source"
          class="mx-2 w-full"
          style="height: 100vh!important;"
        >
          <iframe
            v-if="document_type == 'pdf'"
            :src="`${document_source}`"
            width="100%"
            style="height: 100vh!important;"
            frameborder="0"
            content-disposition="inline"
          />

          <iframe
            v-else
            :src="`${document_source}`"
            width="100%"
            style="height: 100vh!important;"
            frameborder="0"
          />
        </div>
      </b-overlay>
    </b-modal>

  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'View Document',
    },
    file: {
      type: String,
      default: null,
    },
    fileUrl: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      isLoading: true,
      document_type: 'pdf',
      document_source: null,
      view_document_popup: false,
    }
  },

  methods: {
    async viewDocument() {
      if (this.$isNotEmpty(this.fileUrl)) {
        if (this.file.endsWith('pdf')) {
          this.document_type = 'pdf'
          this.document_source = `https://docs.google.com/gview?url=${encodeURIComponent(this.fileUrl)}&embedded=true`
        } else {
          this.document_type = 'docs'
          this.document_source = `https://docs.google.com/gview?url=${encodeURIComponent(this.fileUrl)}&embedded=true`
        }


        this.view_document_popup = true
        this.isLoading = false

        // const response = await fetch(this.document_source)

        // if (response.status === 200) {
        //   this.isLoading = false
        // } else {
        //   this.viewDocument()
        // }
      }
    },
    viewDocumentClosed() {
      this.document_type = 'pdf'
      this.document_source = null

    },

  }
}
</script>

<style lang="scss" scoped>

</style>