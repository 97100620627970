import useJwt from '@/auth/jwt/useJwt'

/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
  return localStorage.getItem('userInfo') && localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
}

export const getUserInfo = () => JSON.parse(localStorage.getItem('userInfo')) || null

export const getOtherProfiles = () => localStorage.getItem('OtherProfiles') || []

export const getInterviewerLink = () => localStorage.getItem('interviewer_link') || null

export const getApplicantLink = () => localStorage.getItem('applicant_link') || null

export const getMeetingEndLink = () => localStorage.getItem('meeting_end_link') || null
