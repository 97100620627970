<template>
  <div>
    <v-select
      v-if="renderSelect"
      id="vSelectPreferredPosition"
      ref="vSelectPreferredPosition"
      v-model="selected_value"
      :multiple="multiple"
      :placeholder="placeholder"
      :options="options"
      :class="validator && selected_value && $isNotEmpty(selected_value) ? '': 'danger-select' "
      :label="`${labelField}`"
      @search="initSearch"
    />

  </div>
</template>

<script>

import { debounce } from 'lodash'
import VSelect from 'vue-select'

export default {
  components: {
    VSelect,
  },
  props: {
    selectedValue: { type: [Array, Object], default: () => {} },
    placeholder: { type: String, default: 'Start typing to get suggestions' },
    stateErrors: { type: Array, default: null },
    multiple: { type: Boolean, default: false },
    allowAddNewRecord: { type: Boolean, default: false },
    validator: { type: Boolean, default: true },
    clearSelect: { type: Boolean, default: false },
    targetDb: { type: String, default: 'hq_dashboard' },
    valueField: { type: String, default: 'value' },
    labelField: { type: String, default: 'text' },
  },
  data() {
    return {
      isFirstLoad: true,
      options: [],
      selected_value: {},

      renderSelect: true,

      popupAddNewRecord: false,
      recordData: {},
      showAlert: {
        cantFindMatch: false,
      },
    }
  },
  watch: {
    selected_value(val, oldVal) {
      if (this.$length(oldVal) > this.$length(val)) {
        this.$emit('option-deleted', val)
      } else if (!this.isFirstLoad) {
        if (!val.is_from_db && this.$isNotEmpty(val.id)) {
          // this.createCompany(null, val)
        }
        this.$emit('option-selected', val)
        this.showAlert.cantFindMatch = false
        this.$emit('show-alert-cant-find-match', this.showAlert.cantFindMatch)
      }
    },
    clearSelect(val) {
      if (val) {
        // this.selected_value = this.multiple ? [] : {}
      }
    },
  },

  updated() {
    // if (this.$isNotEmpty(this.selectedValue)) {
    //   this.selected_value = this.selectedValue
    // }
  },

  mounted() {
    this.selected_value = this.multiple ? [] : {}

    if (this.$isNotEmpty(this.selectedValue)) {
      this.selected_value = this.selectedValue
    }
  },

  methods: {
    initSearch(search, loading) {
      if (search.length > 0) {
        loading(true)
        this.searchForMatch(loading, search, this)
      }
    },

    searchForMatch: debounce((loading, search, vm) => {
      vm.getRecords(search, loading)
    }, 1000),

    getRecords(query, loading = null) {
      this.$set(this, 'options', [])
      const params = {}

      this.$http
        .get(`/api/preferred-position-search/${query}?params=${JSON.stringify(params)}`)
        .then(response => {
          const record = response.data
          if (record.success) {
            this.$set(this, 'options', [])
            record.data.map(data => {
              const item = {
                text: data.text,
                value: data.value,
                is_from_db: true,
                disabled: true,
              }
              this.options.push(item)
            })
          }

          if (this.$isEmpty(this.options)) {
            const option = {
              text: `${query}`,
              value: query,
              is_from_db: false,
              disabled: true,
            }
            this.$set(this, 'options', [option])
            this.showAlert.cantFindMatch = true
            this.$emit('show-alert-cant-find-match', this.showAlert.cantFindMatch)

            // this.$set(this, 'renderSelect', false)
            setTimeout(() => {
              // this.$set(this, 'renderSelect', true)
              setTimeout(() => {
                document.querySelector('#vSelectPreferredPosition input.vs__search').focus()
              }, 50)
            }, 10)
          }

          if (loading) {
            this.isFirstLoad = false
            loading(false)
          }
        })
        .catch(error => {
          this.$handleErrorResponse(error)
        })
    },

    postPreferredPosition(position) {
      this.$http
        .post('/api/options-preferred-position', {
          data: { position },
        })
        .then(response => {
          
        })
        .catch(error => {
          this.$handleErrorResponse(error)
        })
    }
  },
}
</script>
