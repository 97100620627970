<template>
  <div>
    <b-dropdown
      :style="`height: 40px; ${ isMobile ? 'width: 100%' : `min-width: ${buttonMinWidth + 12}ch` }`"
      class="cursor-pointer mr-md-2 float-right"
      :class="{'w-100': isMobile}"
      split
      :variant="currentAction.variant"
      :text="currentAction.text"
      right
      type="filled"
      @click="performAction"
    >
      <template #button-content>
        <div class="d-flex flex-gap-sm align-items-center w-100">
          <div>
            <font-awesome-icon
              :icon="currentAction.icon"
              :style="actionIconStyle"
            />
          </div>
          <div style="">
            {{ currentAction.text }}
          </div>
        </div>
      </template>

      <b-dropdown-item
        v-for="([action_text, action], index) in Object.entries(actionList)"
        :key="action.key"
        :variant="action.item_variant || 'default'"
        :style="`height: 40px; ${ isMobile ? 'width: 100%' : `min-width: ${buttonMinWidth + 15}ch` }`"
        @click="changeAction(action_text)"
      >
        <div class="d-flex flex-gap-sm align-items-center justify-content-start">
          <div class="">
            <font-awesome-icon
              :icon="action.icon"
              :style="actionIconStyle"
            />
          </div>
          <span class="">
            {{ action_text }}
          </span>
        </div>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
export default {
  props: {
    currentAction: {
      type: Object,
      default: () => {
        return {
          icon: 'fa-hand-pointer',
          text: 'Actions',
          variant: 'dark',
        }
      },
    },
    actionList: {
      type: Object,
      default: () => {
        return {}
      },
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
    actionIconStyle: {
      type: Object,
      default: () => {
        return {
          height: '1rem !important',
          width: '1rem !important',
        }
      },
    }
  },
  data() {
    return {
      buttonMinWidth: 12,
    }
  },
  created() {
    Object.keys(this.actionList).forEach(text => {
      if (this.buttonMinWidth < this.$length(text)) {
        this.buttonMinWidth = this.$length(text)
      }
    })
  },
  methods: {
    changeAction(text) {
      this.$emit('change-action', text)
    },
    performAction() {
      this.$emit('perform-action', true)
    },
  },
}
</script>

<style lang="scss" scoped>

</style>