export default [
  {
    path: '/analytics/platform',
    name: 'platform-analytics',
    component: () => import('@/views/analytics/platform-analytics/PlatformAnalytics.vue'),
    meta: {
      breadcrumb: [
        { title: 'Home', url: '/analytics/platform' },
        { title: 'Platform Analytics', active: true },
      ],
      pageTitle: 'Platform Analytics | BETA',
      allowAnonymous: false,
      group: ['employer', 'agency', 'education_institution'],
      section: 'Analytics',
    },
  },

  {
    path: '/analytics/platform/:report_name',
    name: 'platform-analytics-view',
    component: () => import('@/views/analytics/platform-analytics/platform-analytics-view/PlatformAnalyticsView.vue'),
    meta: {
      breadcrumb: [
        { title: 'Home', url: '/analytics/platform' },
        { title: 'Platform Analytics', active: true },
      ],
      pageTitle: 'Platform Analytics | BETA',
      allowAnonymous: false,
      group: ['employer', 'agency', 'education_institution'],
      section: 'Analytics',
    },
  },

  {
    path: '/analytics/organization',
    name: 'corporate-analytics',
    component: () => import('@/views/analytics/OrganizationAnalytics.vue'),
    meta: {
      breadcrumb: [
        { title: 'Home', url: '/analytics/organization' },
        { title: 'Corporate Analytics', active: true },
      ],
      pageTitle: 'Corporate Analytics',
      allowAnonymous: false,
      group: ['agency', 'education_institution'],
      section: 'Analytics',
    },
  },
]
