export default [
  {
    path: '/user-manager',
    name: 'user-manager',
    component: () => import('@/views/user-management-system/UserManager.vue'),
    meta: {
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: 'User Management', active: true },
      ],
      pageTitle: 'User Management | BETA',
      rule: 'editor',
      section: 'User Manager',

      allowAnonymous: false,
    },
  },

  {
    path: '/user-manager/:id',
    name: 'user-manager-view',
    component: () => import('@/views/user-management-system/user-manager-view/UserManagerView.vue'),
    meta: {
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: 'User Details | BETA', active: true },
      ],
      pageTitle: 'User Details | BETA',
      rule: 'editor',
      section: 'User Manager',

      allowAnonymous: false,
    },
  },

  {
    path: '/user-manager/:id/edit',
    name: 'user-manager-edit',
    component: () => import('@/views/user-management-system/user-manager-edit/UserManagerEdit.vue'),
    meta: {
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: 'Edit User Details | BETA', active: true },
      ],
      pageTitle: 'Edit User Details | BETA',
      rule: 'editor',
      section: 'User Manager',

      allowAnonymous: false,
    },
  },
]